@import "../../styles/color";
@import "../../styles/fonts";
.LoginPage {
    .formsSelector {
        display: flex;
        justify-content: space-between;
        button {
            margin: 1rem;
        }
    }
    color: white;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__container {
        padding: 1.5rem;
        background-color: var(--grey500);
        width: 340px;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        // border: 1px solid #dbdbdb;
        user-select: none;
    }
    &__headContent {
        display: flex;
        & img {
            margin-right: 0.5rem;
        }
        margin-bottom: 2rem;
    }
    &__headText {
        color: var(--brand60);
        margin: 0;
    }
    &__emailInput,
    &__passwordInput {
        margin-bottom: 1rem;
        width: 100%;
    }
    &__logoContainer {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        & img {
            margin-right: 1rem;
        }
        h2 {
            margin: 0;
            color: white;
        }
    }
    &__signUpHint {
        margin-top: 1rem;
        width: 100%;
        font-size: 0.825rem;
        & .signUpLink {
            cursor: pointer;
            color: blue;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.Divider {
    width: 100%;
    border-bottom: 1px solid var(--grey400);
    margin: 1.5rem 0rem;
}

.UserInput {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding-bottom: 1rem;
    // margin-bottom: 1rem;
    &__labelContent {
        width: 100%;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        & .preLabelIcon {
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
        }
        & .postLabelIcon {
            margin-left: 0.5rem;
            display: flex;
            align-items: center;
        }
        & label {
            width: 100%;
            color: white;
            & .required {
                color: red;
            }
        }
    }
    &__inputContent {
        width: 100%;
        display: flex;
        border: 1px solid var(--grey600);
        border-radius: 4px;
        transition: all 0.2s ease-in-out;
        &:focus-within {
            // border: 1px solid var(--brand50);
            box-shadow: 0px 0px 8px rgba(0, 87, 77, 0.102);
            border-radius: 4px;
        }
        & .preInputIcon {
            padding: 0rem 0.5rem;
            display: flex;
            align-items: center;
            border-right: 1px solid var(--grey600);
        }
        & .postInputIcon {
            margin: 0rem 0.5rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            // transition: all 0.2s ease-in-out;
            // width: 30px;
            // height: 30px;
            // & svg {
            //     width: 100%;
            //     height: 100%;
            // }
            // &:hover {
            //     transform: scale(1.1);
            // }
        }
    }
    & .error {
        border: 1px solid var(--red60);
    }
    &__inputError {
        position: absolute;
        color: var(--red60);
        display: flex;
        align-items: flex-start;
        margin-top: 0.25rem;
        bottom: -0.2rem;
        & .errorIcon {
            width: 1rem;
            height: 1rem;
            margin-right: 0.2rem;
            display: flex;
            align-items: center;
            & svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    & input {
        width: 100%;
        padding: 0.375rem 0.5rem;
        border-radius: 4px;
        border: none;
        background-color: var(--grey500);
        // border: 1px solid #919191;
        border-radius: 4px;
        color: var(--grey400);
        outline: none;
        &:focus {
            outline: none;
        }
        &:active {
            outline: none;
        }
        &::placeholder {
            color: var(--grey600);
        }
        &:-webkit-autofill {
            background-color: var(--grey500);
        }
    }
}

.Button {
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid var(--brand100);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .s400;
    & svg {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
    }
    &--primary {
        @extend .Button;
        background-color: var(--brand100);
        color: var(--white);
        // &:hover {
        //     // @extend .Button--secondary;
        //     // opacity: 0.8;
        // }
    }
    &--secondary {
        @extend .Button;
        background-color: var(--white);
        color: var(--brand100);
        // &:hover {
        //     // @extend .Button--primary;
        //     // opacity: 0.8;
        // }
    }
    &:hover {
        opacity: 0.7;
    }
}
