:root {
  --grey100: #fcfcfc;
  --grey200: #efefef;
  --grey300: #d9d9d9;
  --grey400: #c3c3c3;
  --grey500: #282828;
  --grey600: #525252;
  --grey700: #343434;
  --grey800: #2e2e2e;
  --grey900: #252525;
  --grey1000: #1a1a1a;
  --white: #ffffff;
  --lightBlue: rgba(123, 97, 255, 0.12);
  --purple: #6f38c5;
  --red60: #d22600;
  --brand10: #e2f4f2;
  --brand20: #bee8e3;
  --brand30: #64c5ba;
  --brand40: #38a699;
  --brand50: #107d70;
  --brand60: #00574d;
  --brand70: #01423a;
  --brand80: #00302a;
  --brand90: #001d19;
  --brand100: #6f38c5;
}

.r100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 400;
}

.r200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 400;
}

.r300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 400;
}

.r400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 400;
}

.r500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 400;
}

.r600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 400;
}

.r700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 400;
}

.r800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 400;
}

.r900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 400;
}

.r1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 400;
}

.r2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 400;
}

.b100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 700;
}

.b200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 700;
}

.b300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 700;
}

.b400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 700;
}

.b500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 700;
}

.b600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 700;
}

.b700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 700;
}

.b800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 700;
}

.b900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 700;
}

.b1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 700;
}

.b2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 700;
}

.s100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 600;
}

.s200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 600;
}

.s300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 600;
}

.s400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
}

.s500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 600;
}

.s600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 600;
}

.s700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 600;
}

.s800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 600;
}

.s900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 600;
}

.s1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 600;
}

.s2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 600;
}

.m100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 500;
}

.m200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 500;
}

.m300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 500;
}

.m400, .InputField .countryCodeSelector {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 500;
}

.m500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 500;
}

.m600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 500;
}

.m700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 500;
}

.m800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 500;
}

.m900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 500;
}

.m1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 500;
}

.m2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 500;
}

.InputField {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
}
.InputField label {
  color: white;
  margin-bottom: 0.5rem;
}
.InputField label span {
  color: red;
}
.InputField .field {
  display: flex;
  width: 100%;
}
.InputField .countryCodeSelector, .InputField input {
  width: 100%;
  border: 1px solid #919191;
  outline: none;
  border-radius: 4px;
  padding: 0.5rem;
  background-color: transparent;
  color: white;
}
.InputField .countryCodeSelector {
  display: "inline";
  width: -moz-max-content;
  width: max-content;
  margin-right: 0.5rem;
  background-color: var(--grey500);
  font-family: "Inter", sans-serif;
}/*# sourceMappingURL=inputField.css.map */