.FilePicker {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 0.5rem;
  background-color: transparent;
  border: 1px solid #919191;
  border-radius: 4px;
  color: white;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.FilePicker input[type=file] {
  display: none;
}
.FilePicker--disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
.FilePicker--disabled svg {
  opacity: 0.8;
  animation: rotateInfinte 1s linear infinite;
}
.FilePicker svg {
  width: 24px;
  height: 24px;
}
.FilePicker .placeHolderText {
  width: 100%;
  color: #616161;
}
.FilePicker .fileList {
  z-index: 200;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0rem;
  top: 2.5rem;
  width: 100%;
  border: 1px solid #919191;
  border-radius: 4px;
  border-top: 0px;
  height: auto;
  background: #202020;
}
.FilePicker .fileList ul {
  list-style: none;
  margin: 0;
  width: 100%;
  padding: 0;
  overflow-y: auto;
  max-height: 200px;
}
.FilePicker .fileList ul li {
  display: flex;
  align-items: center;
  width: 100%;
  color: #fff;
  padding: 1rem 0.75rem;
}
.FilePicker .fileList ul li:hover {
  background-color: var(--grey700);
  color: #fff;
}
.FilePicker .fileList ul li img {
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  background-color: #fff;
  border-radius: 50%;
  padding: 0.2rem;
}
.FilePicker .fileList ul .defaultOption {
  color: white;
  background-color: transparent;
  width: 100%;
  text-align: center;
}
.FilePicker .fileList ul .defaultOption:hover {
  background-color: transparent;
  pointer-events: none !important;
}
.FilePicker .fileList .uploadContainer {
  cursor: default;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0.75rem;
  color: #fff;
  cursor: pointer;
  border: none;
  border-top: 1px solid #4f4f4f;
}
.FilePicker .fileList .uploadContainer:hover {
  background-color: var(--grey700);
  color: #fff;
}
.FilePicker .fileList .uploadContainer svg {
  margin-right: 0.5rem;
}
.FilePicker .fileList .uploadContainer span {
  color: #fff;
}
.FilePicker .fileList .uploadContainer button {
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
}

@keyframes rotateInfinte {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.customScroll {
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.customScroll::-webkit-scrollbar {
  width: 8px;
  height: 200px;
}
.customScroll::-webkit-scrollbar-track {
  background: transparent;
}
.customScroll::-webkit-scrollbar-thumb {
  background: var(--grey600);
  border-radius: 10px;
}
.customScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.lds-dual-ring {
  display: inline-block;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #fff;
  animation: shrinkGrow 1s linear infinite;
  border-radius: 50%;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 0px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shrinkGrow {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1.5);
  }
}/*# sourceMappingURL=FilePicker.css.map */