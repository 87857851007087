:root {
  --grey100: #fcfcfc;
  --grey200: #efefef;
  --grey300: #d9d9d9;
  --grey400: #c3c3c3;
  --grey500: #282828;
  --grey600: #525252;
  --grey700: #343434;
  --grey800: #2e2e2e;
  --grey900: #252525;
  --grey1000: #1a1a1a;
  --white: #ffffff;
  --lightBlue: rgba(123, 97, 255, 0.12);
  --purple: #6f38c5;
  --red60: #d22600;
  --brand10: #e2f4f2;
  --brand20: #bee8e3;
  --brand30: #64c5ba;
  --brand40: #38a699;
  --brand50: #107d70;
  --brand60: #00574d;
  --brand70: #01423a;
  --brand80: #00302a;
  --brand90: #001d19;
  --brand100: #6f38c5;
}

.r100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 400;
}

.r200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 400;
}

.r300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 400;
}

.r400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 400;
}

.r500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 400;
}

.r600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 400;
}

.r700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 400;
}

.r800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 400;
}

.r900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 400;
}

.r1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 400;
}

.r2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 400;
}

.b100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 700;
}

.b200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 700;
}

.b300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 700;
}

.b400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 700;
}

.b500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 700;
}

.b600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 700;
}

.b700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 700;
}

.b800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 700;
}

.b900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 700;
}

.b1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 700;
}

.b2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 700;
}

.s100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 600;
}

.s200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 600;
}

.s300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 600;
}

.s400, .Button, .Button--secondary, .Button--primary {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
}

.s500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 600;
}

.s600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 600;
}

.s700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 600;
}

.s800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 600;
}

.s900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 600;
}

.s1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 600;
}

.s2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 600;
}

.m100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 500;
}

.m200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 500;
}

.m300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 500;
}

.m400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 500;
}

.m500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 500;
}

.m600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 500;
}

.m700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 500;
}

.m800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 500;
}

.m900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 500;
}

.m1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 500;
}

.m2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 500;
}

.LoginPage {
  color: white;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LoginPage .formsSelector {
  display: flex;
  justify-content: space-between;
}
.LoginPage .formsSelector button {
  margin: 1rem;
}
.LoginPage__container {
  padding: 1.5rem;
  background-color: var(--grey500);
  width: 340px;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.LoginPage__headContent {
  display: flex;
  margin-bottom: 2rem;
}
.LoginPage__headContent img {
  margin-right: 0.5rem;
}
.LoginPage__headText {
  color: var(--brand60);
  margin: 0;
}
.LoginPage__emailInput, .LoginPage__passwordInput {
  margin-bottom: 1rem;
  width: 100%;
}
.LoginPage__logoContainer {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
.LoginPage__logoContainer img {
  margin-right: 1rem;
}
.LoginPage__logoContainer h2 {
  margin: 0;
  color: white;
}
.LoginPage__signUpHint {
  margin-top: 1rem;
  width: 100%;
  font-size: 0.825rem;
}
.LoginPage__signUpHint .signUpLink {
  cursor: pointer;
  color: blue;
}
.LoginPage__signUpHint .signUpLink:hover {
  text-decoration: underline;
}

.Divider {
  width: 100%;
  border-bottom: 1px solid var(--grey400);
  margin: 1.5rem 0rem;
}

.UserInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-bottom: 1rem;
}
.UserInput__labelContent {
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}
.UserInput__labelContent .preLabelIcon {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}
.UserInput__labelContent .postLabelIcon {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}
.UserInput__labelContent label {
  width: 100%;
  color: white;
}
.UserInput__labelContent label .required {
  color: red;
}
.UserInput__inputContent {
  width: 100%;
  display: flex;
  border: 1px solid var(--grey600);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.UserInput__inputContent:focus-within {
  box-shadow: 0px 0px 8px rgba(0, 87, 77, 0.102);
  border-radius: 4px;
}
.UserInput__inputContent .preInputIcon {
  padding: 0rem 0.5rem;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--grey600);
}
.UserInput__inputContent .postInputIcon {
  margin: 0rem 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.UserInput .error {
  border: 1px solid var(--red60);
}
.UserInput__inputError {
  position: absolute;
  color: var(--red60);
  display: flex;
  align-items: flex-start;
  margin-top: 0.25rem;
  bottom: -0.2rem;
}
.UserInput__inputError .errorIcon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.2rem;
  display: flex;
  align-items: center;
}
.UserInput__inputError .errorIcon svg {
  width: 100%;
  height: 100%;
}
.UserInput input {
  width: 100%;
  padding: 0.375rem 0.5rem;
  border-radius: 4px;
  border: none;
  background-color: var(--grey500);
  border-radius: 4px;
  color: var(--grey400);
  outline: none;
}
.UserInput input:focus {
  outline: none;
}
.UserInput input:active {
  outline: none;
}
.UserInput input::-moz-placeholder {
  color: var(--grey600);
}
.UserInput input::placeholder {
  color: var(--grey600);
}
.UserInput input:-webkit-autofill {
  background-color: var(--grey500);
}

.Button, .Button--secondary, .Button--primary {
  width: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border: 1px solid var(--brand100);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Button svg, .Button--secondary svg, .Button--primary svg {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}
.Button--primary {
  background-color: var(--brand100);
  color: var(--white);
}
.Button--secondary {
  background-color: var(--white);
  color: var(--brand100);
}
.Button:hover, .Button--primary:hover, .Button--secondary:hover {
  opacity: 0.7;
}/*# sourceMappingURL=LoginPage.css.map */