.CreateJobForm {
  background-color: #202020;
  padding: 2.5rem;
  color: white;
  min-width: 500px;
  max-width: 800px;
  width: 70%;
  height: auto;
}
.CreateJobForm__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.CreateJobForm__header .heading {
  margin: 0px;
}
.CreateJobForm__header .closeButton {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.CreateJobForm__header .closeButton:hover {
  opacity: 0.7;
}
.CreateJobForm__header .closeButton svg {
  width: 24px;
  height: 24px;
}
.CreateJobForm__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.CreateJobForm__content .inputField {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.75rem;
}
.CreateJobForm__content .inputField label {
  margin-bottom: 0.5rem;
}
.CreateJobForm__content .inputField .jobFile,
.CreateJobForm__content .inputField .userInput {
  padding: 0.5rem;
  background-color: transparent;
  border: 1px solid #919191;
  border-radius: 4px;
  color: white;
  outline: none;
}
.CreateJobForm__content .inputField input[type=number], .CreateJobForm__content .inputField input[type=text] {
  padding: 0.5rem;
  background-color: transparent;
  border: 1px solid #919191;
  border-radius: 4px;
  color: white;
  outline: none;
}
.CreateJobForm__content .inputField input[type=number]::-moz-placeholder, .CreateJobForm__content .inputField input[type=text]::-moz-placeholder {
  color: #616161;
}
.CreateJobForm__content .inputField input[type=number]::placeholder, .CreateJobForm__content .inputField input[type=text]::placeholder {
  color: #616161;
}
.CreateJobForm__content .inputField .required::after {
  content: "*";
  color: red;
  padding-left: 2px;
}
.CreateJobForm__content .inputField .printQuantity {
  display: flex;
  justify-content: space-between;
}
.CreateJobForm__content .inputField .printQuantity input {
  max-width: 60%;
}
.CreateJobForm__content .inputField .printQuantity button {
  cursor: pointer;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
}
.CreateJobForm__content .inputField .printQuantity button svg {
  width: 30px;
  height: 30px;
}
.CreateJobForm__content .blankSpace {
  min-width: 50%;
}
.CreateJobForm__content .jobModeContainer {
  width: 100%;
  margin-bottom: 1rem;
}
.CreateJobForm__content .jobModeContainer label {
  margin-bottom: 0.5rem;
}
.CreateJobForm__content .jobModeContainer .required::after {
  content: "*";
  color: red;
  padding-left: 2px;
}
.CreateJobForm__content .jobModeContainer .cardsContainer {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.CreateJobForm__content .jobModeContainer .cardsContainer .modeCard {
  cursor: pointer;
  width: 48%;
  border: 1px solid rgba(83, 83, 83, 0.8);
  border-radius: 4px;
  padding: 1rem 1.25rem;
}
.CreateJobForm__content .jobModeContainer .cardsContainer .modeCard span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CreateJobForm__content .jobModeContainer .cardsContainer .modeCard span svg {
  width: 20px;
  height: 20px;
}
.CreateJobForm__content .jobModeContainer .cardsContainer .modeCard:hover {
  background-color: #191919;
}
.CreateJobForm__content .jobModeContainer .cardsContainer .modeCard--selected {
  background-color: #282828;
}
.CreateJobForm__content .jobModeContainer .cardsContainer .modeCard--selected:hover {
  background-color: #282828;
}
.CreateJobForm__content .jobModeContainer .cardsContainer .modeCard .description {
  color: #ababab;
}
.CreateJobForm__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CreateJobForm__footer .startPrint__container {
  display: flex;
  align-items: center;
}
.CreateJobForm__footer .startPrint__container input[type=checkbox] {
  width: 1rem;
  height: 1rem;
  margin: 0;
  margin-right: 0.25rem;
  cursor: pointer;
}
.CreateJobForm__footer .actionButtons {
  display: flex;
  align-items: center;
}
.CreateJobForm__footer .actionButtons .Button {
  padding: 0.75rem 1.5rem;
  color: white;
  border-radius: 4px;
  margin-left: 0.75rem;
  border: none;
}
.CreateJobForm__footer .actionButtons .Button:disabled {
  pointer-events: none;
  opacity: 0.7;
}
.CreateJobForm__footer .actionButtons .Button:hover {
  opacity: 0.7;
}
.CreateJobForm__footer .actionButtons .Button--primary {
  background-color: var(--purple);
}
.CreateJobForm__footer .actionButtons .Button--secondary {
  background-color: #202020;
}/*# sourceMappingURL=createJobForm.css.map */