:root {
  --grey100: #fcfcfc;
  --grey200: #efefef;
  --grey300: #d9d9d9;
  --grey400: #c3c3c3;
  --grey500: #282828;
  --grey600: #525252;
  --grey700: #343434;
  --grey800: #2e2e2e;
  --grey900: #252525;
  --grey1000: #1a1a1a;
  --white: #ffffff;
  --lightBlue: rgba(123, 97, 255, 0.12);
  --purple: #6f38c5;
  --red60: #d22600;
  --brand10: #e2f4f2;
  --brand20: #bee8e3;
  --brand30: #64c5ba;
  --brand40: #38a699;
  --brand50: #107d70;
  --brand60: #00574d;
  --brand70: #01423a;
  --brand80: #00302a;
  --brand90: #001d19;
  --brand100: #6f38c5;
}

.PrinterPicker {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 0.5rem;
  background-color: transparent;
  border: 1px solid #919191;
  border-radius: 4px;
  color: white;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.PrinterPicker .printerList {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0rem;
  top: 2.5rem;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #919191;
  border-top: 0px;
  height: auto;
  background: #202020;
}
.PrinterPicker .printerList--up {
  top: unset;
  bottom: 2.5rem;
  border: 1px solid #919191;
  border-bottom: 0px;
}
.PrinterPicker .printerList ul {
  list-style: none;
  margin: 0;
  width: 100%;
  padding: 0;
  overflow-y: auto;
  max-height: 200px;
}
.PrinterPicker .printerList ul li {
  display: flex;
  align-items: center;
  width: 100%;
  color: #fff;
  padding: 1rem 0.75rem;
}
.PrinterPicker .printerList ul li:hover {
  background-color: var(--grey700);
  color: #fff;
}
.PrinterPicker .printerList ul li img {
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  background-color: #fff;
  border-radius: 50%;
  padding: 0.2rem;
}
.PrinterPicker .printerList ul li span {
  line-height: 150%;
}
.PrinterPicker .printerList ul li svg {
  margin-right: 0.5rem;
  width: 24px;
  height: 24px;
}/*# sourceMappingURL=printerPicker.css.map */