.Files {
    width: 100%;
    // height: 100%;
    height: 90vh;
    max-height: 60vh;
    // background-color: var(--grey900);
    // background-color: #191919;
    background-color: #222222;
    &__mainContainer {
        height: 100%;
        margin: 0rem auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        padding: 1rem;
    }
    &__content {
        width: 100%;
        display: flex;
        align-items: center;
        height: 100%;
        & .heading {
            margin: 0px;
            color: var(--grey200);
        }
        &__list {
            color: var(--grey400);
            list-style: none;
            padding: 0;
            width: 100%;
            overflow-y: auto;
            height: 100%;
            // overflow: visible;
            // min-width: 720px;
            margin: 0;
            & .fileItem {
                position: relative;
                padding: 0.8rem 1rem;
                width: 100%;
                display: flex;
                align-items: center;
                margin: 0;
                border-bottom: 1px solid #3a3a3a;
                // margin-bottom: 0.5rem;
                justify-content: space-between;
                // &:hover {
                //     background-color: #282828;
                // }
                &:last-child {
                    border-bottom: none;
                }
                .fileDetails {
                    display: flex;
                    padding-right: 1rem;
                    margin-right: 1rem;
                    justify-content: space-between;
                    align-items: center;
                    // width: 40%;
                    // border-right: 1px solid white;
                }
            }
            & .subMenu {
                margin-left: 1.5rem;
                // border-left: 1px solid white;
                // width: 0.25rem;
                // height: 0.25rem;
                // background-color: white;
                // border-radius: 50%;
                position: relative;
                display: flex;
                padding: 0;
                padding-left: 1rem;
                flex-direction: row;
                li {
                    list-style: none;
                    margin-left: 0.5rem;
                    cursor: pointer;
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                    &:hover {
                        color: var(--brand100);
                    }
                }
            }
        }
    }
}
