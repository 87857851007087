.Files {
  width: 100%;
  height: 90vh;
  max-height: 60vh;
  background-color: #222222;
}
.Files__mainContainer {
  height: 100%;
  margin: 0rem auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
.Files__content {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}
.Files__content .heading {
  margin: 0px;
  color: var(--grey200);
}
.Files__content__list {
  color: var(--grey400);
  list-style: none;
  padding: 0;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  margin: 0;
}
.Files__content__list .fileItem {
  position: relative;
  padding: 0.8rem 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid #3a3a3a;
  justify-content: space-between;
}
.Files__content__list .fileItem:last-child {
  border-bottom: none;
}
.Files__content__list .fileItem .fileDetails {
  display: flex;
  padding-right: 1rem;
  margin-right: 1rem;
  justify-content: space-between;
  align-items: center;
}
.Files__content__list .subMenu {
  margin-left: 1.5rem;
  position: relative;
  display: flex;
  padding: 0;
  padding-left: 1rem;
  flex-direction: row;
}
.Files__content__list .subMenu li {
  list-style: none;
  margin-left: 0.5rem;
  cursor: pointer;
}
.Files__content__list .subMenu li svg {
  width: 24px;
  height: 24px;
}
.Files__content__list .subMenu li:hover {
  color: var(--brand100);
}/*# sourceMappingURL=files.css.map */