.Overview {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Overview .downloadConfig,
.Overview .addNewPrinter {
  min-width: 330px;
  padding: 1.5rem;
  background-color: #202020;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  color: white;
}
.Overview .downloadConfig .header,
.Overview .addNewPrinter .header {
  margin: 0;
  margin-bottom: 1rem;
}
.Overview .downloadConfig .actionButtons,
.Overview .addNewPrinter .actionButtons {
  display: flex;
  justify-content: flex-end;
}
.Overview .downloadConfig .actionButtons .Button,
.Overview .addNewPrinter .actionButtons .Button {
  max-width: 100px;
  margin-left: 0.5rem;
}
.Overview .downloadConfig .linkText,
.Overview .addNewPrinter .linkText {
  color: var(--brand100);
}
.Overview .downloadConfig {
  max-width: 330px;
}
.Overview .downloadConfig p {
  margin-top: 0;
  margin-bottom: 2rem;
}
.Overview .downloadConfig .linkText {
  cursor: pointer;
}
.Overview .downloadConfig .linkText:hover {
  text-decoration: underline;
}

.OverviewCard {
  background: #282828;
  border: 2px solid #282828;
  border-radius: 6.71429px;
  padding: 1.75rem;
  width: 49%;
  display: flex;
  color: white;
  justify-content: space-between;
}
.OverviewCard__details {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.OverviewCard__details .heading {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.OverviewCard__details .heading svg {
  width: 32px;
  height: 32px;
  margin-right: 0.5rem;
}
.OverviewCard__details .value {
  font-size: 3rem;
}/*# sourceMappingURL=overview.css.map */