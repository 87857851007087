.SideNav {
  width: 300px;
  min-width: 250px;
  height: 100vh;
  background-color: #191919;
  display: flex;
  flex-direction: column;
  padding: 2.25rem 1.5rem;
  position: relative;
}
.SideNav__logoContainer {
  width: 100%;
}
.SideNav__menu {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.SideNav__footerMenuItems, .SideNav__menuItems {
  margin: 2rem 0px;
}
.SideNav__footerMenuItems .menuItem, .SideNav__menuItems .menuItem {
  display: flex;
  align-items: center;
  background-color: #191919;
  padding: 0.8rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.SideNav__footerMenuItems .menuItem svg, .SideNav__menuItems .menuItem svg {
  margin-right: 0.5rem;
  color: var(--grey100);
  width: 20px;
  height: 20px;
}
.SideNav__footerMenuItems .menuItem span, .SideNav__menuItems .menuItem span {
  color: var(--grey100);
}
.SideNav__footerMenuItems .menuItem:hover, .SideNav__menuItems .menuItem:hover {
  background-color: var(--grey800);
}
.SideNav__footerMenuItems .menuItem--selected, .SideNav__menuItems .menuItem--selected {
  background-color: var(--lightBlue);
}
.SideNav__footerMenuItems .menuItem--selected:hover, .SideNav__menuItems .menuItem--selected:hover {
  background-color: var(--lightBlue);
}
.SideNav__footerMenuItems {
  margin: 0rem 0px;
}/*# sourceMappingURL=sideNav.css.map */