:root {
  --grey100: #fcfcfc;
  --grey200: #efefef;
  --grey300: #d9d9d9;
  --grey400: #c3c3c3;
  --grey500: #282828;
  --grey600: #525252;
  --grey700: #343434;
  --grey800: #2e2e2e;
  --grey900: #252525;
  --grey1000: #1a1a1a;
  --white: #ffffff;
  --lightBlue: rgba(123, 97, 255, 0.12);
  --purple: #6f38c5;
  --red60: #d22600;
  --brand10: #e2f4f2;
  --brand20: #bee8e3;
  --brand30: #64c5ba;
  --brand40: #38a699;
  --brand50: #107d70;
  --brand60: #00574d;
  --brand70: #01423a;
  --brand80: #00302a;
  --brand90: #001d19;
  --brand100: #6f38c5;
}

.r100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 400;
}

.r200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 400;
}

.r300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 400;
}

.r400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 400;
}

.r500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 400;
}

.r600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 400;
}

.r700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 400;
}

.r800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 400;
}

.r900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 400;
}

.r1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 400;
}

.r2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 400;
}

.b100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 700;
}

.b200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 700;
}

.b300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 700;
}

.b400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 700;
}

.b500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 700;
}

.b600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 700;
}

.b700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 700;
}

.b800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 700;
}

.b900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 700;
}

.b1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 700;
}

.b2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 700;
}

.s100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 600;
}

.s200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 600;
}

.s300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 600;
}

.s400, .Button, .Button--secondary, .Button--primary {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
}

.s500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 600;
}

.s600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 600;
}

.s700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 600;
}

.s800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 600;
}

.s900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 600;
}

.s1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 600;
}

.s2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 600;
}

.m100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 500;
}

.m200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 500;
}

.m300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 500;
}

.m400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 500;
}

.m500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 500;
}

.m600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 500;
}

.m700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 500;
}

.m800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 500;
}

.m900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 500;
}

.m1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 500;
}

.m2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 500;
}

.Onboarding {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  min-height: 60vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Onboarding__container {
  min-width: 400px;
  width: 100%;
  max-width: 500px;
  background-color: var(--grey500);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  border-radius: 0.25rem;
}
.Onboarding .formContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Onboarding .formContainer .formHeading {
  margin: 0;
  margin-bottom: 24px;
  text-align: center;
}

.OnboardingSteps__stepDetails {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.OnboardingSteps__stepDetails span:last-child {
  margin-top: 1rem;
}
.OnboardingSteps .Button:nth-child(3), .OnboardingSteps .Button--secondary:nth-child(3), .OnboardingSteps .Button--primary:nth-child(3) {
  margin-top: 1rem;
}
.OnboardingSteps .configDownloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}
.OnboardingSteps .configDownloader__fileImage {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--brand100);
  width: 100px;
  height: 100px;
}
.OnboardingSteps .configDownloader__fileImage img {
  height: 60%;
}
.OnboardingSteps .configDownloader .onboardingDesc {
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: #d1d1d1;
}
.OnboardingSteps .configDownloader .fileDownloadButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: var(--grey700);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}
.OnboardingSteps .configDownloader .fileDownloadButton:hover {
  opacity: 0.7;
}
.OnboardingSteps .configDownloader .fileDownloadButton::after {
  border-radius: 4px;
  content: "";
  position: absolute;
  left: 0;
  box-shadow: white 0px 0px 30px 10px;
  width: 100%;
  height: 100%;
  animation: glowInOut 2s ease-in-out infinite;
}
.OnboardingSteps .configDownloader .fileDownloadButton .fileDetails {
  display: flex;
  align-items: center;
}
.OnboardingSteps .configDownloader .fileDownloadButton .fileDetails span {
  font-family: "Manrope", sans-serif;
}
.OnboardingSteps .configDownloader .fileDownloadButton .fileDetails img {
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
}

@keyframes glowInOut {
  0% {
    box-shadow: var(--brand100) 0px 0px 12px 12px;
  }
  50% {
    box-shadow: var(--brand100) 0px 0px 0px 0px;
  }
  100% {
    box-shadow: var(--brand100) 0px 0px 12px 12px;
  }
}
@keyframes rotateInfinte {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Button, .Button--secondary, .Button--primary {
  width: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border: 1px solid var(--brand100);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Button svg, .Button--secondary svg, .Button--primary svg {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}
.Button--primary {
  background-color: var(--brand100);
  color: var(--white);
}
.Button--secondary {
  background-color: var(--grey500);
  color: var(--white);
  border: 1px solid #919191;
}
.Button:hover, .Button--primary:hover, .Button--secondary:hover {
  opacity: 0.7;
}
.Button:disabled, .Button--primary:disabled, .Button--secondary:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}/*# sourceMappingURL=onboarding.css.map */