// $greys: ( 10: #f8f8f8, 20: #eeecec, 30: #e5e5e5, 40: #dbdbdb, 50: #c2c2c2, 60: #919191, 70: #626262, 80: #575757, 90: #424242, 100: #282828, );
// //
$brand: (
    10: #e2f4f2,
    20: #bee8e3,
    30: #64c5ba,
    40: #38a699,
    50: #107d70,
    60: #00574d,
    70: #01423a,
    80: #00302a,
    90: #001d19,
    100: #6f38c5,
);
// //
$red: (
    60: #d22600,
);
// //
$greys: (
    100: #fcfcfc,
    200: #efefef,
    300: #d9d9d9,
    400: #c3c3c3,
    500: #282828,
    600: #525252,
    700: #343434,
    800: #2e2e2e,
    900: #252525,
    1000: #1a1a1a,
);
:root {
    @each $val, $color in $greys {
        --grey#{$val}: #{$color};
    }
    --white: #ffffff;
    --lightBlue: rgba(123, 97, 255, 0.12);
    --purple: #6f38c5;
    @each $val, $color in $red {
        --red#{$val}: #{$color};
    }
    @each $val, $color in $brand {
        --brand#{$val}: #{$color};
    }
}

// :root {
// // --white: #ffffff;
// --lightGray: #fafaf9;
// --gray: #f2f2f2;
// --darkGray: #9f9f9f;
// --darkGray2: #d9d9d9;
// --lightPurple: #e6e1f5;
// --lightPurple: #e6e1f5;
// --iconGray: #9ba0a8;
// --green: #51d176;
// @each $val,
// $color in $greys {
//     --grey#{$val}: #{$color};
// }
// --black: #000000;
// @each $val,
// $color in $brand {
//     --brand#{$val}: #{$color};
// }
// @each $val,
// $color in $red {
//     --red#{$val}: #{$color};
// }
// }
// $backgroundColor: var(--brand10);
