.Printers {
  width: 100%;
  // height: 100%;
  height: 90vh;
  max-height: 60vh;
  // background-color: var(--grey900);
  // background-color: #191919;
  background-color: #222222;
  &__mainContainer {
    height: 100%;
    margin: 0rem auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding: 1rem;
  }
  &__content {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    & .heading {
      margin: 0px;
      color: var(--grey200);
    }
    &__list {
      color: var(--grey400);
      list-style: none;
      padding: 0;
      height: 100%;
      width: 100%;
      // overflow: visible;
      // min-width: 720px;
      margin: 0;
      & .printerItem {
        position: relative;
        padding: 0.8rem 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0;
        border-bottom: 1px solid #3a3a3a;
        // margin-bottom: 0.5rem;
        justify-content: space-between;
        // &:hover {
        //     background-color: #282828;
        // }
        &:last-child {
          border-bottom: none;
        }
        .printerDetails {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .printerNameStatus {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              min-width: 200px;
              max-width: 250px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .printerOfflineStatus,
            .printerOnlineStatus {
              margin-left: 1rem;
              padding: 0.25rem 0.5rem;
              background-color: #83d181;
              color: white;
              border-radius: 0.25rem;
              font-size: 0.875rem;
            }
            .printerOfflineStatus {
              background-color: #d18181;
            }
            .printerNameVersion {
              display: flex;
              align-items: center;
            }
          }
          .printerUpdateContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 120px;

            .printerFirmwareVersion {
              margin-left: 1rem;
              padding: 0.25rem 0.5rem;
              color: #000000;
              background-color: rgb(197, 191, 191);
              border-radius: 0.25rem;
              font-size: 0.875rem;
            }

            .printerUpdate {
              cursor: pointer;
              margin-left: 1rem;
              > div {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
              }
              svg {
                width: 24px;
                height: 24px;
              }
              &:hover {
                color: var(--brand100);
              }
            }
          }
        }
      }
      & .subMenu {
        margin-left: 1.5rem;
        border-left: 1px solid white;
        // width: 0.25rem;
        // height: 0.25rem;
        // background-color: white;
        // border-radius: 50%;
        position: relative;
        display: flex;
        padding: 0;
        padding-left: 1rem;
        flex-direction: row;
        li {
          list-style: none;
          margin-left: 0.5rem;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 24px;
            height: 24px;
          }
          &:hover {
            color: var(--brand100);
          }
        }
        // &:hover {
        //     opacity: 0.8;
        // }
        // &::before,
        // &::after {
        //     content: "";
        //     width: 0.25rem;
        //     height: 0.25rem;
        //     background-color: white;
        //     border-radius: 50%;
        //     position: absolute;
        //     top: -0.5rem;
        // }
        // &::after {
        //     top: 0.5rem;
        // }
      }
      // .subMenuExpanded {
      //     position: absolute;
      //     color: white;
      //     width: max-content;
      //     right: 0rem;
      //     background-color: var(--grey700);
      //     margin: 0;
      //     padding: 0;
      //     z-index: 10;
      //     & li {
      //         padding: 1rem 1rem;
      //         list-style: none;
      //         span {
      //             color: white;
      //         }
      //     }
      // }
    }
  }
}

.NoPrinterPlaceHolder {
  // width: 100%;
  height: 100%;
  // background-color: var(--grey500);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__content {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  svg {
    width: 56px;
    height: 56px;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1.5rem;
    color: var(--grey400);
  }
  .Button {
  }
}
