@mixin fontClass($type, $num, $fontSize) {
    .#{$type}#{$num} {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-size: #{$fontSize}rem;
        @if $num <=700 {
            line-height: 140%;
        } @else {
            line-height: 120%;
        }
        @if $type== "r" {
            font-weight: 400;
        }
        @if $type== "b" {
            font-weight: 700;
        }
        @if $type== "s" {
            font-weight: 600;
        }
        @if $type== "m" {
            font-weight: 500;
        }
    }
}

$types: ("r", "b", "s", "m");
$typography: (
    100: 10,
    200: 12,
    300: 14,
    400: 16,
    500: 18,
    600: 20,
    700: 24,
    800: 28,
    900: 36,
    1000: 40,
    2000: 80,
);
//
//
@each $type in $types {
    @each $num, $fontSize in $typography {
        @include fontClass($type, $num, calc($fontSize/16));
    }
}
