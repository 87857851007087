.Dashboard {
  height: 100%;
  overflow: hidden;
  min-width: 1200px;
  display: flex;
  flex-direction: column;
}

.TabNav {
  display: flex;
  &__tab {
    padding: 10px 16px;
    color: white;
    cursor: pointer;
    position: relative;
  }
  &__active {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: #6f38c5;
    }
  }
}
