@import "../../../styles/color";
.PrinterPicker {
    user-select: none;
    padding: 0.5rem;
    background-color: transparent;
    border: 1px solid #919191;
    border-radius: 4px;
    color: white;
    outline: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
    .printerList {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0rem;
        top: 2.5rem;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #919191;
        border-top: 0px;
        // min-height: 200px;
        // max-height: 200px;
        height: auto;
        // height: 100px;
        // background-color: var(--grey400);
        background: #202020;
        // overflow: hidden;
        // overflow-y: auto;
        &--up {
            top: unset;
            bottom: 2.5rem;
            border: 1px solid #919191;
            border-bottom: 0px;
        }
        ul {
            list-style: none;
            // min-height: 40%;
            margin: 0;
            width: 100%;
            padding: 0;
            overflow-y: auto;
            max-height: 200px;
            li {
                display: flex;
                align-items: center;
                width: 100%;
                color: #fff;
                padding: 1rem 0.75rem;
                // justify-content: space-between;
                // border-bottom: 1px solid var(--grey400);
                &:hover {
                    background-color: var(--grey700);
                    color: #fff;
                }
                img {
                    width: 30px;
                    height: 30px;
                    margin-right: 0.5rem;
                    background-color: #fff;
                    border-radius: 50%;
                    padding: 0.2rem;
                }
                span {
                    line-height: 150%;
                }
                svg {
                    margin-right: 0.5rem;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}
