.Overview {
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .downloadConfig,
    .addNewPrinter {
        min-width: 330px;
        padding: 1.5rem;
        background-color: #202020;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        color: white;
        .header {
            margin: 0;
            margin-bottom: 1rem;
        }
        .actionButtons {
            display: flex;
            justify-content: flex-end;
            .Button {
                max-width: 100px;
                margin-left: 0.5rem;
            }
        }
        .linkText {
            color: var(--brand100);
        }
    }
    .downloadConfig {
        max-width: 330px;
        p {
            margin-top: 0;
            margin-bottom: 2rem;
        }
        .linkText {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.OverviewCard {
    background: #282828;
    border: 2px solid #282828;
    border-radius: 6.71429px;
    padding: 1.75rem;
    width: 49%;
    display: flex;
    color: white;
    justify-content: space-between;
    &__details {
        display: flex;
        flex-direction: column;
        width: 50%;
        .heading {
            display: flex;
            align-items: center;
            svg {
                width: 32px;
                height: 32px;
                margin-right: 0.5rem;
            }
            margin-bottom: 1rem;
        }
        .value {
            font-size: 3rem;
        }
    }
}
