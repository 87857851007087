.Jobs {
  width: 100%;
  height: 90vh;
  max-height: 60vh;
  // background-color: var(--grey900);
  background-color: #191919;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  overflow: hidden;
  &__mainContainer {
    height: 100%;
    width: 100%;
    // margin: 0rem auto;
    // width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding: 2rem;
  }
  &__headingContainer {
    color: var(--grey200);
    width: 100%;
    display: flex;
    justify-content: space-between;
    h1 {
      margin: 0px;
    }
    margin-bottom: 2rem;
  }
  &__content {
    height: 100%;
    display: flex;
    width: 100%;
    overflow-y: hidden;
  }
}

.JobsList {
  // background-color: rgba(255, 255, 255, 0.04);
  background-color: #222222;
  padding: 1.25rem;
  min-width: 410px;
  &__types {
    display: flex;
    margin-bottom: 1rem;
    & .jobTypeTab {
      color: var(--grey200);
      padding: 0.5rem 1rem;
      cursor: pointer;
      &:hover {
        background-color: var(--grey600);
      }
      &--selected {
        background-color: #343434;
        // background-color: var(--grey600);
        &:hover {
          background-color: #343434;
        }
      }
      &:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
      &:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }
  &__list {
    // display: flex;
    width: 100%;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    padding-right: 0.25rem;
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      height: 200px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--grey600);
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.JobCard {
  // border: 1px solid #2a2a2a;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
  max-width: 360px;
  cursor: pointer;
  &:hover {
    background-color: var(--grey700);
  }
  &--selected {
    background-color: var(--grey700);
  }
  & .ProgressIndicator {
    height: 8px;
    width: 100%;
  }
  &--createdJob {
    & .ProgressIndicator {
      background-color: var(--grey200);
    }
  }
  &--completedJob {
    & .ProgressIndicator {
      background-color: #83d181;
    }
  }
  &--stoppedJob {
    & .ProgressIndicator {
      background-color: #d18181;
    }
  }
  &--runningJob {
    & .ProgressIndicator {
      display: flex;
      background-color: transparent;
      width: 100%;
      position: relative;
      .pendingLine {
        background-color: #81a1d1;
        height: 100%;
        width: 100%;
        opacity: 0.3;
      }
      .solidLine {
        height: 100%;
        background-color: #81a1d1;
      }
      .runningLine {
        height: 100%;
        background-color: #81a1d1;
        overflow: hidden;
        .vertical-stripes {
          // border: solid 1px red;
          // opacity: 0.6;
          // background: repeating-linear-gradient(-45deg, #fff, #fff 0.5rem, #81a1d1 0.5rem, #81a1d1 1rem);
          background: repeating-linear-gradient(
            -45deg,
            #fff 25%,
            #fff 50%,
            #81a1d1 50%,
            #81a1d1 75%
          );
          height: 100%;
          width: calc(100% + 20px);
          margin-left: -20px;
          animation: loader 1s linear infinite;
          background-size: 20px 20px;
        }
        @keyframes loader {
          0% {
            transform: translateX(0);
            // background-position: 90% 0;
          }
          100% {
            transform: translateX(20px);
            // background-position: 0 0;
          }
        }
      }
    }
  }
  &__jobDetails {
    border: 1px solid var(--grey700);
    padding: 1.125rem 1rem;
    display: flex;
    width: 100%;
    border-radius: 0px;
    align-items: center;
    & .jobInfo {
      color: var(--grey200);
      display: flex;
      flex-direction: column;
      & svg {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
      }
      &__jobName {
        margin: 0;
        margin-bottom: 0.25rem;
      }
      &__jobTime,
      &__jobFile {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        & span {
          max-width: 190px;
          color: var(--grey400);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      &__jobTime {
        margin-bottom: 0px;
      }
    }
  }
}

.JobPreview {
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  .jobDataContainer {
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 8px;
      height: 200px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--grey600);
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  &__jobDetails {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    & .jobInfo {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      width: 60%;

      &__jobName {
        margin: 0;
        margin-bottom: 0.25rem;
      }
      &__jobTime {
        & svg {
          width: 20px;
          height: 20px;
          margin-right: 0.3em;
        }
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        margin-right: 1rem;
        & span {
          max-width: 190px;
          color: var(--grey400);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      color: var(--grey200);
      & .details {
        flex-wrap: wrap;
        display: flex;
        & svg {
          width: 20px;
          height: 20px;
          margin-right: 0.3em;
        }
        &__jobStatus,
        &__jobTime,
        &__jobFile {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          margin-right: 1rem;
          & span {
            max-width: 190px;
            color: var(--grey400);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        &__jobStatus {
          &--completed {
            color: #83d181;
            & span {
              color: #83d181;
            }
          }
          &--running {
            color: #81a1d1;
            svg {
              transition: all 0.5s ease-in-out;
              animation: float 1s linear infinite;
            }
            & span {
              color: #81a1d1;
            }
          }
          &--stopped {
            color: #d18181;
            & span {
              color: #d18181;
            }
          }
          &--created {
            color: var(--grey200);
            & span {
              color: var(--grey200);
            }
          }
        }
      }
    }
    & .jobActions {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &__imageButton {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background-color: var(--brand100);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 1rem;
        &:hover {
          opacity: 0.7;
        }
        svg {
          width: 24px;
          height: 24px;
          color: white;
        }
      }
      &__imageButtonPlaceholder {
        width: 40px;
        height: 40px;
        border-radius: none;
        background-color: transparent !important;
      }
    }
  }
  &__printDetails {
    // width: min-content;
    display: grid;
    gap: 1.5rem 1.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 1.5rem;
  }
  &__printerDetails {
  }
}

.detailsCard {
  padding: 1rem 1.25rem;
  color: var(--grey200);
  background-color: #282828;
  border-radius: 0.25rem;
  // max-width: 200px;
  min-width: 200px;
  &__heading {
    display: flex;
    align-items: center;
    svg {
      min-width: 20px;
      min-height: 20px;
      margin-right: 0.5rem;
    }
    margin-bottom: 0.5rem;
  }
}

.PrintersList {
  & .heading {
    color: var(--grey100);
  }
  &__list {
    color: var(--grey100);
    list-style: none;
    padding: 0;
    & .printerItem {
      padding: 0.5rem 1rem;
      width: 100%;
      display: flex;
      margin: 0;
      margin-bottom: 0.5rem;
      justify-content: space-between;
      border-bottom: 1px solid #3a3a3a;
      align-items: center;
      // cursor: pointer;
      // &:hover {
      //     background-color: #282828;
      // }
      .printerNameStatus {
        padding-left: 1.5rem;
        position: relative;
        display: flex;
        width: 40%;
        justify-content: space-between;
        align-items: center;
        .printerOfflineIndicator,
        .printerOnlineIndicator {
          position: absolute;
          left: -0rem;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
        }
        .printerOfflineIndicator {
          background-color: #d18181;
        }
        .printerOnlineIndicator {
          background-color: #83d181;
        }
        .printerSpecificControls {
          display: flex;
          align-items: center;
          .iconControl {
            margin: 0rem 0.25rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
              width: 1.125rem;
              height: 1.125rem;
            }
            &--disabled {
              display: none;
            }
          }
        }
      }
      .pdata {
        width: 50%;
        display: flex;
        justify-content: space-between;
        span {
          display: flex;
          margin-left: 1rem;
        }
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes float {
  0% {
    transform: translate(0px, 2px);
  }
  25% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -2px);
  }
  75% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 2px);
  }
}

.jobThumbnail {
  width: 84px;
  min-width: 84px;
  height: 84px;
  min-height: 84px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--grey100);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.125rem;
  & img {
    width: 100%;
    height: 100%;
  }
  &--small {
    width: 64px;
    min-width: 64px;
    height: 64px;
    min-height: 64px;
  }
}

.NoJobPlaceHolder {
  // width: 100%;
  height: 100%;
  // background-color: var(--grey500);
  background-color: #222222;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__content {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  svg {
    width: 56px;
    height: 56px;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1.5rem;
    color: var(--grey400);
  }
  .Button {
  }
}
