.SideNav {
    width: 300px;
    min-width: 250px;
    height: 100vh;
    // background-color: var(--grey900);
    background-color: #191919;
    display: flex;
    flex-direction: column;
    padding: 2.25rem 1.5rem;
    position: relative;
    &__logoContainer {
        width: 100%;
    }
    &__menu {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
    &__footerMenuItems,
    &__menuItems {
        margin: 2rem 0px;
        & .menuItem {
            display: flex;
            align-items: center;
            background-color: #191919;
            // background-color: var(--grey900);
            padding: 0.8rem 1rem;
            border-radius: 0.25rem;
            cursor: pointer;
            margin-bottom: 0.5rem;
            svg {
                margin-right: 0.5rem;
                color: var(--grey100);
                width: 20px;
                height: 20px;
            }
            span {
                color: var(--grey100);
            }
            &:hover {
                background-color: var(--grey800);
            }
            &--selected {
                background-color: var(--lightBlue);
                &:hover {
                    background-color: var(--lightBlue);
                }
            }
        }
    }
    &__footerMenuItems {
        margin: 0rem 0px;
    }
}