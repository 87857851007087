.UserDataFormPage {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        border: 2px solid black;
        padding: 1rem 1.5rem;
        border-radius: 1rem;
        * {
            font-size: 1.3rem;
        }
        select,
        input {
            margin-bottom: 1rem;
            font-size: 1.5rem;
        }
    }
}