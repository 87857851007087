@import "../../../styles/color";
@import "../../../styles/fonts";
.InputField {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 24px;
    & label {
        color: white;
        & span {
            color: red;
        }
        margin-bottom: 0.5rem;
    }
    .field {
        display: flex;
        width: 100%;
    }
    & .countryCodeSelector,
    & input {
        width: 100%;
        border: 1px solid #919191;
        outline: none;
        border-radius: 4px;
        padding: 0.5rem;
        background-color: transparent;
        color: white;
    }
    & .countryCodeSelector {
        display: "inline";
        width: max-content;
        margin-right: 0.5rem;
        // -webkit-appearance: none;
        // -moz-appearance: none;
        // appearance: none;
        background-color: var(--grey500);
        font-family: "Inter", sans-serif;
        @extend .m400;
    }
}
