.Printers {
  width: 100%;
  height: 90vh;
  max-height: 60vh;
  background-color: #222222;
}
.Printers__mainContainer {
  height: 100%;
  margin: 0rem auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
.Printers__content {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
}
.Printers__content .heading {
  margin: 0px;
  color: var(--grey200);
}
.Printers__content__list {
  color: var(--grey400);
  list-style: none;
  padding: 0;
  height: 100%;
  width: 100%;
  margin: 0;
}
.Printers__content__list .printerItem {
  position: relative;
  padding: 0.8rem 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid #3a3a3a;
  justify-content: space-between;
}
.Printers__content__list .printerItem:last-child {
  border-bottom: none;
}
.Printers__content__list .printerItem .printerDetails {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.Printers__content__list .printerItem .printerDetails .printerNameStatus {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Printers__content__list .printerItem .printerDetails .printerNameStatus span {
  min-width: 200px;
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.Printers__content__list .printerItem .printerDetails .printerNameStatus .printerOfflineStatus,
.Printers__content__list .printerItem .printerDetails .printerNameStatus .printerOnlineStatus {
  margin-left: 1rem;
  padding: 0.25rem 0.5rem;
  background-color: #83d181;
  color: white;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}
.Printers__content__list .printerItem .printerDetails .printerNameStatus .printerOfflineStatus {
  background-color: #d18181;
}
.Printers__content__list .printerItem .printerDetails .printerNameStatus .printerNameVersion {
  display: flex;
  align-items: center;
}
.Printers__content__list .printerItem .printerDetails .printerUpdateContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 120px;
}
.Printers__content__list .printerItem .printerDetails .printerUpdateContainer .printerFirmwareVersion {
  margin-left: 1rem;
  padding: 0.25rem 0.5rem;
  color: #000000;
  background-color: rgb(197, 191, 191);
  border-radius: 0.25rem;
  font-size: 0.875rem;
}
.Printers__content__list .printerItem .printerDetails .printerUpdateContainer .printerUpdate {
  cursor: pointer;
  margin-left: 1rem;
}
.Printers__content__list .printerItem .printerDetails .printerUpdateContainer .printerUpdate > div {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}
.Printers__content__list .printerItem .printerDetails .printerUpdateContainer .printerUpdate svg {
  width: 24px;
  height: 24px;
}
.Printers__content__list .printerItem .printerDetails .printerUpdateContainer .printerUpdate:hover {
  color: var(--brand100);
}
.Printers__content__list .subMenu {
  margin-left: 1.5rem;
  border-left: 1px solid white;
  position: relative;
  display: flex;
  padding: 0;
  padding-left: 1rem;
  flex-direction: row;
}
.Printers__content__list .subMenu li {
  list-style: none;
  margin-left: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Printers__content__list .subMenu li svg {
  width: 24px;
  height: 24px;
}
.Printers__content__list .subMenu li:hover {
  color: var(--brand100);
}

.NoPrinterPlaceHolder {
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.NoPrinterPlaceHolder__content {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NoPrinterPlaceHolder svg {
  width: 56px;
  height: 56px;
  margin-bottom: 1rem;
}
.NoPrinterPlaceHolder p {
  margin-bottom: 1.5rem;
  color: var(--grey400);
}/*# sourceMappingURL=Printers.css.map */