@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");
:root {
  --grey100: #fcfcfc;
  --grey200: #efefef;
  --grey300: #d9d9d9;
  --grey400: #c3c3c3;
  --grey500: #282828;
  --grey600: #525252;
  --grey700: #343434;
  --grey800: #2e2e2e;
  --grey900: #252525;
  --grey1000: #1a1a1a;
  --white: #ffffff;
  --lightBlue: rgba(123, 97, 255, 0.12);
  --purple: #6f38c5;
  --red60: #d22600;
  --brand10: #e2f4f2;
  --brand20: #bee8e3;
  --brand30: #64c5ba;
  --brand40: #38a699;
  --brand50: #107d70;
  --brand60: #00574d;
  --brand70: #01423a;
  --brand80: #00302a;
  --brand90: #001d19;
  --brand100: #6f38c5;
}

.r100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 400;
}

.r200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 400;
}

.r300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 400;
}

.r400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 400;
}

.r500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 400;
}

.r600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 400;
}

.r700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 400;
}

.r800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 400;
}

.r900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 400;
}

.r1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 400;
}

.r2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 400;
}

.b100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 700;
}

.b200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 700;
}

.b300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 700;
}

.b400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 700;
}

.b500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 700;
}

.b600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 700;
}

.b700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 700;
}

.b800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 700;
}

.b900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 700;
}

.b1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 700;
}

.b2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 700;
}

.s100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 600;
}

.s200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 600;
}

.s300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 600;
}

.s400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
}

.s500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 600;
}

.s600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 600;
}

.s700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 600;
}

.s800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 600;
}

.s900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 600;
}

.s1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 600;
}

.s2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 600;
}

.m100 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 140%;
  font-weight: 500;
}

.m200 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 140%;
  font-weight: 500;
}

.m300 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 500;
}

.m400 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 500;
}

.m500 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 140%;
  font-weight: 500;
}

.m600 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 500;
}

.m700 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 500;
}

.m800 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 500;
}

.m900 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 120%;
  font-weight: 500;
}

.m1000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 500;
}

.m2000 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 5rem;
  line-height: 120%;
  font-weight: 500;
}

.App {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #191919;
  justify-content: center;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  background-color: var(--gray);
}

.button, .button--secondary, .button--primarywithIcon, .button--primary {
  max-height: 48px;
  padding: 0.75rem 1rem;
  outline: none;
  border: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  border-radius: 0.25rem;
  line-height: 120%;
  cursor: pointer;
}
.button:hover, .button--secondary:hover, .button--primarywithIcon:hover, .button--primary:hover {
  opacity: 0.6;
}
.button:disabled, .button--secondary:disabled, .button--primarywithIcon:disabled, .button--primary:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.button--primary {
  background-color: var(--purple);
  color: var(--white);
}
.button--primarywithIcon {
  background-color: var(--purple);
  color: var(--white);
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
}
.button--primarywithIcon svg {
  margin: auto 0rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
.button--secondary {
  background-color: #191919;
  color: white;
  border: 2px solid white;
}/*# sourceMappingURL=index.css.map */