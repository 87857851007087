.mainContainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    align-items: center;
    // overflow: hidden;
    height: 100%;
    padding: 2rem;
    min-height: 100vh;
}
