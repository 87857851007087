.TopNav {
  width: 100%;
  color: white;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}
.TopNav__actions {
  display: flex;
  align-items: center;
}

.DropdownMenu {
  position: relative;
}
.DropdownMenu .dropdownMenu__button {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.DropdownMenu .dropdownMenu__button:hover {
  opacity: 0.7;
}
.DropdownMenu .dropdownMenu__button svg {
  width: 20px;
  height: 20px;
  margin: 0rem 0.5rem;
}
.DropdownMenu .expandedMenu {
  margin: 0;
  z-index: 100;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  top: 3rem;
  right: 2rem;
  background-color: #202020;
  border-radius: 0.25rem;
  padding: 1rem 0.75rem;
  list-style: none;
}
.DropdownMenu .expandedMenu .dropdownHR {
  border: 1px solid #4f4f4f;
  margin-bottom: 1rem;
}
.DropdownMenu .expandedMenu__item {
  min-width: 200px;
  margin-bottom: 1rem;
}
.DropdownMenu .expandedMenu__item .menuName {
  margin: 0;
  margin-bottom: 1rem;
}
.DropdownMenu .expandedMenu__item .propertyPreview {
  display: flex;
  align-items: center;
}
.DropdownMenu .expandedMenu__item .propertyPreview svg {
  margin-left: 1rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.DropdownMenu .expandedMenu__item .propertyPreview svg:hover {
  opacity: 0.7;
}
.DropdownMenu .expandedMenu__item .propertyPreview .propertyValue {
  border: 1px solid #919191;
  padding: 0.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.DropdownMenu .expandedMenu__item .propertyPreview .refreshIcon {
  width: 20px;
  height: 20px;
}
.DropdownMenu .expandedMenu .dropdownButton {
  display: flex;
  align-items: center;
  margin-bottom: 0rem;
  cursor: pointer;
  padding: 0.5rem 0rem;
}
.DropdownMenu .expandedMenu .dropdownButton:hover {
  opacity: 0.7;
}
.DropdownMenu .expandedMenu .dropdownButton svg {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}
.DropdownMenu .expandedMenu .NetworkSettings {
  min-width: 330px;
  padding: 1.5rem;
  background-color: #202020;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
}
.DropdownMenu .expandedMenu .NetworkSettings .header {
  margin: 0;
  margin-bottom: 1rem;
}
.DropdownMenu .expandedMenu .NetworkSettings .actionButtons {
  display: flex;
  justify-content: flex-end;
}
.DropdownMenu .expandedMenu .NetworkSettings .actionButtons .Button {
  max-width: 100px;
  margin-left: 0.5rem;
}/*# sourceMappingURL=topNav.css.map */