.UserConfirmationBox {
    max-width: 500px;
    color: white;
    background-color: var(--grey500);
    padding: 1.5rem;
    border-radius: 0.5rem;
    h2 {
        margin: 0;
        margin-bottom: 1.5rem;
    }
    .actionButtons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .Button {
            max-width: 100px;
            margin-left: 0.5rem;
        }
    }
}
