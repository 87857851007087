.Jobs {
  width: 100%;
  height: 90vh;
  max-height: 60vh;
  background-color: #191919;
  overflow: hidden;
}
.Jobs__mainContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Jobs__headingContainer {
  color: var(--grey200);
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.Jobs__headingContainer h1 {
  margin: 0px;
}
.Jobs__content {
  height: 100%;
  display: flex;
  width: 100%;
  overflow-y: hidden;
}

.JobsList {
  background-color: #222222;
  padding: 1.25rem;
  min-width: 410px;
}
.JobsList__types {
  display: flex;
  margin-bottom: 1rem;
}
.JobsList__types .jobTypeTab {
  color: var(--grey200);
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.JobsList__types .jobTypeTab:hover {
  background-color: var(--grey600);
}
.JobsList__types .jobTypeTab--selected {
  background-color: #343434;
}
.JobsList__types .jobTypeTab--selected:hover {
  background-color: #343434;
}
.JobsList__types .jobTypeTab:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.JobsList__types .jobTypeTab:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.JobsList__list {
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding-right: 0.25rem;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.JobsList__list::-webkit-scrollbar {
  width: 8px;
  height: 200px;
}
.JobsList__list::-webkit-scrollbar-track {
  background: transparent;
}
.JobsList__list::-webkit-scrollbar-thumb {
  background: var(--grey600);
  border-radius: 10px;
}
.JobsList__list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.JobCard {
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
  max-width: 360px;
  cursor: pointer;
}
.JobCard:hover {
  background-color: var(--grey700);
}
.JobCard--selected {
  background-color: var(--grey700);
}
.JobCard .ProgressIndicator {
  height: 8px;
  width: 100%;
}
.JobCard--createdJob .ProgressIndicator {
  background-color: var(--grey200);
}
.JobCard--completedJob .ProgressIndicator {
  background-color: #83d181;
}
.JobCard--stoppedJob .ProgressIndicator {
  background-color: #d18181;
}
.JobCard--runningJob .ProgressIndicator {
  display: flex;
  background-color: transparent;
  width: 100%;
  position: relative;
}
.JobCard--runningJob .ProgressIndicator .pendingLine {
  background-color: #81a1d1;
  height: 100%;
  width: 100%;
  opacity: 0.3;
}
.JobCard--runningJob .ProgressIndicator .solidLine {
  height: 100%;
  background-color: #81a1d1;
}
.JobCard--runningJob .ProgressIndicator .runningLine {
  height: 100%;
  background-color: #81a1d1;
  overflow: hidden;
}
.JobCard--runningJob .ProgressIndicator .runningLine .vertical-stripes {
  background: repeating-linear-gradient(-45deg, #fff 25%, #fff 50%, #81a1d1 50%, #81a1d1 75%);
  height: 100%;
  width: calc(100% + 20px);
  margin-left: -20px;
  animation: loader 1s linear infinite;
  background-size: 20px 20px;
}
@keyframes loader {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(20px);
  }
}
.JobCard__jobDetails {
  border: 1px solid var(--grey700);
  padding: 1.125rem 1rem;
  display: flex;
  width: 100%;
  border-radius: 0px;
  align-items: center;
}
.JobCard__jobDetails .jobInfo {
  color: var(--grey200);
  display: flex;
  flex-direction: column;
}
.JobCard__jobDetails .jobInfo svg {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}
.JobCard__jobDetails .jobInfo__jobName {
  margin: 0;
  margin-bottom: 0.25rem;
}
.JobCard__jobDetails .jobInfo__jobTime, .JobCard__jobDetails .jobInfo__jobFile {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.JobCard__jobDetails .jobInfo__jobTime span, .JobCard__jobDetails .jobInfo__jobFile span {
  max-width: 190px;
  color: var(--grey400);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.JobCard__jobDetails .jobInfo__jobTime {
  margin-bottom: 0px;
}

.JobPreview {
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  background-color: #222222;
  display: flex;
  flex-direction: column;
}
.JobPreview .jobDataContainer {
  overflow-y: auto;
  overflow-x: hidden;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.JobPreview .jobDataContainer::-webkit-scrollbar {
  width: 8px;
  height: 200px;
}
.JobPreview .jobDataContainer::-webkit-scrollbar-track {
  background: transparent;
}
.JobPreview .jobDataContainer::-webkit-scrollbar-thumb {
  background: var(--grey600);
  border-radius: 10px;
}
.JobPreview .jobDataContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.JobPreview__jobDetails {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}
.JobPreview__jobDetails .jobInfo {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  color: var(--grey200);
}
.JobPreview__jobDetails .jobInfo__jobName {
  margin: 0;
  margin-bottom: 0.25rem;
}
.JobPreview__jobDetails .jobInfo__jobTime {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}
.JobPreview__jobDetails .jobInfo__jobTime svg {
  width: 20px;
  height: 20px;
  margin-right: 0.3em;
}
.JobPreview__jobDetails .jobInfo__jobTime span {
  max-width: 190px;
  color: var(--grey400);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.JobPreview__jobDetails .jobInfo .details {
  flex-wrap: wrap;
  display: flex;
}
.JobPreview__jobDetails .jobInfo .details svg {
  width: 20px;
  height: 20px;
  margin-right: 0.3em;
}
.JobPreview__jobDetails .jobInfo .details__jobStatus, .JobPreview__jobDetails .jobInfo .details__jobTime, .JobPreview__jobDetails .jobInfo .details__jobFile {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}
.JobPreview__jobDetails .jobInfo .details__jobStatus span, .JobPreview__jobDetails .jobInfo .details__jobTime span, .JobPreview__jobDetails .jobInfo .details__jobFile span {
  max-width: 190px;
  color: var(--grey400);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.JobPreview__jobDetails .jobInfo .details__jobStatus--completed {
  color: #83d181;
}
.JobPreview__jobDetails .jobInfo .details__jobStatus--completed span {
  color: #83d181;
}
.JobPreview__jobDetails .jobInfo .details__jobStatus--running {
  color: #81a1d1;
}
.JobPreview__jobDetails .jobInfo .details__jobStatus--running svg {
  transition: all 0.5s ease-in-out;
  animation: float 1s linear infinite;
}
.JobPreview__jobDetails .jobInfo .details__jobStatus--running span {
  color: #81a1d1;
}
.JobPreview__jobDetails .jobInfo .details__jobStatus--stopped {
  color: #d18181;
}
.JobPreview__jobDetails .jobInfo .details__jobStatus--stopped span {
  color: #d18181;
}
.JobPreview__jobDetails .jobInfo .details__jobStatus--created {
  color: var(--grey200);
}
.JobPreview__jobDetails .jobInfo .details__jobStatus--created span {
  color: var(--grey200);
}
.JobPreview__jobDetails .jobActions {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.JobPreview__jobDetails .jobActions__imageButton {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: var(--brand100);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 1rem;
}
.JobPreview__jobDetails .jobActions__imageButton:hover {
  opacity: 0.7;
}
.JobPreview__jobDetails .jobActions__imageButton svg {
  width: 24px;
  height: 24px;
  color: white;
}
.JobPreview__jobDetails .jobActions__imageButtonPlaceholder {
  width: 40px;
  height: 40px;
  border-radius: none;
  background-color: transparent !important;
}
.JobPreview__printDetails {
  display: grid;
  gap: 1.5rem 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1.5rem;
}
.detailsCard {
  padding: 1rem 1.25rem;
  color: var(--grey200);
  background-color: #282828;
  border-radius: 0.25rem;
  min-width: 200px;
}
.detailsCard__heading {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.detailsCard__heading svg {
  min-width: 20px;
  min-height: 20px;
  margin-right: 0.5rem;
}

.PrintersList .heading {
  color: var(--grey100);
}
.PrintersList__list {
  color: var(--grey100);
  list-style: none;
  padding: 0;
}
.PrintersList__list .printerItem {
  padding: 0.5rem 1rem;
  width: 100%;
  display: flex;
  margin: 0;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  border-bottom: 1px solid #3a3a3a;
  align-items: center;
}
.PrintersList__list .printerItem .printerNameStatus {
  padding-left: 1.5rem;
  position: relative;
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}
.PrintersList__list .printerItem .printerNameStatus .printerOfflineIndicator,
.PrintersList__list .printerItem .printerNameStatus .printerOnlineIndicator {
  position: absolute;
  left: 0rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}
.PrintersList__list .printerItem .printerNameStatus .printerOfflineIndicator {
  background-color: #d18181;
}
.PrintersList__list .printerItem .printerNameStatus .printerOnlineIndicator {
  background-color: #83d181;
}
.PrintersList__list .printerItem .printerNameStatus .printerSpecificControls {
  display: flex;
  align-items: center;
}
.PrintersList__list .printerItem .printerNameStatus .printerSpecificControls .iconControl {
  margin: 0rem 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.PrintersList__list .printerItem .printerNameStatus .printerSpecificControls .iconControl svg {
  width: 1.125rem;
  height: 1.125rem;
}
.PrintersList__list .printerItem .printerNameStatus .printerSpecificControls .iconControl--disabled {
  display: none;
}
.PrintersList__list .printerItem .pdata {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.PrintersList__list .printerItem .pdata span {
  display: flex;
  margin-left: 1rem;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes float {
  0% {
    transform: translate(0px, 2px);
  }
  25% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -2px);
  }
  75% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 2px);
  }
}
.jobThumbnail {
  width: 84px;
  min-width: 84px;
  height: 84px;
  min-height: 84px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--grey100);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.125rem;
}
.jobThumbnail img {
  width: 100%;
  height: 100%;
}
.jobThumbnail--small {
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
}

.NoJobPlaceHolder {
  height: 100%;
  background-color: #222222;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.NoJobPlaceHolder__content {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NoJobPlaceHolder svg {
  width: 56px;
  height: 56px;
  margin-bottom: 1rem;
}
.NoJobPlaceHolder p {
  margin-bottom: 1.5rem;
  color: var(--grey400);
}/*# sourceMappingURL=jobs.css.map */