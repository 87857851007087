.CreateJobForm {
    background-color: #202020;
    padding: 2.5rem;
    color: white;
    min-width: 500px;
    max-width: 800px;
    width: 70%;
    // min-height: 200px;
    height: auto;
    // overflow: hidden;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        & .heading {
            margin: 0px;
        }
        & .closeButton {
            padding: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
            & svg {
                width: 24px;
                height: 24px;
            }
        }
    }
    &__content {
        width: 100%;
        // height: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        & .inputField {
            width: 48%;
            display: flex;
            flex-direction: column;
            margin-bottom: 1.75rem;
            label {
                margin-bottom: 0.5rem;
            }
            .jobFile,
            .userInput {
                padding: 0.5rem;
                background-color: transparent;
                border: 1px solid #919191;
                border-radius: 4px;
                color: white;
                outline: none;
            }
            & input[type="number"],
            & input[type="text"] {
                padding: 0.5rem;
                background-color: transparent;
                border: 1px solid #919191;
                border-radius: 4px;
                color: white;
                outline: none;
                &::placeholder {
                    color: #616161;
                }
            }
            & input[type="number"] {
            }
            .required {
                &::after {
                    content: "*";
                    color: red;
                    padding-left: 2px;
                }
            }
            .printQuantity {
                display: flex;
                justify-content: space-between;
                input {
                    max-width: 60%;
                }
                button {
                    // padding: 0.5rem;
                    cursor: pointer;
                    border-radius: 0.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    outline: none;
                    border: none;
                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
        .blankSpace {
            min-width: 50%;
        }
        .jobModeContainer {
            width: 100%;
            margin-bottom: 1rem;
            label {
                margin-bottom: 0.5rem;
            }
            .required {
                &::after {
                    content: "*";
                    color: red;
                    padding-left: 2px;
                }
            }
            .cardsContainer {
                margin-top: 0.5rem;
                margin-bottom: 1rem;
                display: flex;
                width: 100%;
                justify-content: space-between;
                .modeCard {
                    cursor: pointer;
                    width: 48%;
                    border: 1px solid rgba(83, 83, 83, 0.8);
                    border-radius: 4px;
                    padding: 1rem 1.25rem;
                    & span {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                    &:hover {
                        background-color: #191919;
                    }
                    &--selected {
                        background-color: #282828;
                        &:hover {
                            background-color: #282828;
                        }
                    }
                    .description {
                        color: #ababab;
                    }
                }
            }
        }
    }
    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .startPrint {
            &__container {
                display: flex;
                align-items: center;
                input[type="checkbox"] {
                    width: 1rem;
                    height: 1rem;
                    margin: 0;
                    margin-right: 0.25rem;
                    cursor: pointer;
                }
            }
        }
        .actionButtons {
            display: flex;
            align-items: center;
            .Button {
                padding: 0.75rem 1.5rem;
                color: white;
                border-radius: 4px;
                margin-left: 0.75rem;
                border: none;
                &:disabled {
                    pointer-events: none;
                    opacity: 0.7;
                }
                &:hover {
                    opacity: 0.7;
                }
                &--primary {
                    background-color: var(--purple);
                }
                &--secondary {
                    background-color: #202020;
                    // border: 1px solid #919191;
                }
            }
        }
    }
}
