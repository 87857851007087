.TopNav {
    width: 100%;
    color: white;
    // position: sticky;
    // top: 0;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    &__actions {
        display: flex;
        align-items: center;
    }
}

.DropdownMenu {
    position: relative;
    .dropdownMenu__button {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
        svg {
            width: 20px;
            height: 20px;
            margin: 0rem 0.5rem;
        }
    }
    .expandedMenu {
        margin: 0;
        z-index: 100;
        width: max-content;
        position: absolute;
        top: 3rem;
        right: 2rem;
        background-color: #202020;
        border-radius: 0.25rem;
        padding: 1rem 0.75rem;
        list-style: none;
        .dropdownHR {
            border: 1px solid #4f4f4f;
            margin-bottom: 1rem;
        }
        &__item {
            min-width: 200px;
            margin-bottom: 1rem;
            .menuName {
                margin: 0;
                margin-bottom: 1rem;
            }
            .propertyPreview {
                display: flex;
                align-items: center;
                // display: flex;
                svg {
                    margin-left: 1rem;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    &:hover {
                        opacity: 0.7;
                    }
                }
                .propertyValue {
                    border: 1px solid #919191;
                    padding: 0.5rem;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                }
                .refreshIcon {
                    // margin-left: 0.5rem;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .dropdownButton {
            display: flex;
            align-items: center;
            margin-bottom: 0rem;
            cursor: pointer;
            padding: 0.5rem 0rem;
            &:hover {
                opacity: 0.7;
            }
            svg {
                width: 20px;
                height: 20px;
                margin-right: 0.5rem;
            }
        }
        .NetworkSettings {
            min-width: 330px;
            padding: 1.5rem;
            background-color: #202020;
            border-radius: 0.5rem;
            display: flex;
            flex-direction: column;
            .header {
                margin: 0;
                margin-bottom: 1rem;
            }
            .actionButtons {
                display: flex;
                justify-content: flex-end;
                .Button {
                    max-width: 100px;
                    margin-left: 0.5rem;
                }
            }
        }
    }
}
