@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
    background-color: var(--gray);
}

.button {
    max-height: 48px;
    padding: 0.75rem 1rem;
    outline: none;
    border: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    border-radius: 0.25rem;
    line-height: 120%;
    cursor: pointer;
    // border: 1px solid var(--purple);
    &:hover {
        opacity: 0.6;
    }
    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
    &--primary {
        @extend .button;
        background-color: var(--purple);
        color: var(--white);
    }
    &--primarywithIcon {
        @extend .button;
        background-color: var(--purple);
        color: var(--white);
        display: flex;
        align-items: center;
        // padding: 0.5rem 1rem;
        padding: 0.75rem 1.5rem;
        svg {
            margin: auto 0rem;
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
        }
    }
    &--secondary {
        @extend .button;
        background-color: #191919;
        color: white;
        border: 2px solid white;
    }
}
