@import "../../styles/color";
@import "../../styles/fonts";
.Onboarding {
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    min-height: calc(60vh);
    width: 100%;
    // min-height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__container {
        min-width: 400px;
        width: 100%;
        max-width: 500px;
        // min-height: 500px;
        background-color: var(--grey500);
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 24px;
        border-radius: 0.25rem;
    }
    .formContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .formHeading {
            margin: 0;
            margin-bottom: 24px;
            text-align: center;
        }
    }
}

.OnboardingSteps {
    &__stepDetails {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        span {
            &:last-child {
                margin-top: 1rem;
            }
        }
        .stepDesc {
        }
    }
    & .Button {
        &:nth-child(3) {
            margin-top: 1rem;
        }
    }
    & .configDownloader {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.5rem;
        &__fileImage {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid var(--brand100);
            width: 100px;
            height: 100px;
            // &::after {
            //     content: "";
            //     position: absolute;
            //     // border: 10px solid white;
            //     box-shadow: white 0px 0px 30px 10px;
            //     // border-top: 3px solid white;
            //     // border-bottom: 3px solid white;
            //     border-radius: 50%;
            //     width: 100px;
            //     height: 100px;
            //     animation: glowInOut 2s ease-in-out infinite;
            // }
            & img {
                height: 60%;
            }
        }
        .onboardingDesc {
            margin-top: 1rem;
            margin-bottom: 2rem;
            color: #d1d1d1;
        }
        & .fileDownloadButton {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5rem 1rem;
            width: 100%;
            background-color: var(--grey700);
            border-radius: 4px;
            position: relative;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
            &::after {
                border-radius: 4px;
                content: "";
                position: absolute;
                left: 0; // border: 10px solid white;
                box-shadow: white 0px 0px 30px 10px;
                // border-top: 3px solid white;
                // border-bottom: 3px solid white;
                // border-radius: 50%;
                width: 100%;
                height: 100%;
                animation: glowInOut 2s ease-in-out infinite;
            }
            .fileDetails {
                display: flex;
                align-items: center;
                span {
                    font-family: "Manrope", sans-serif;
                }
                & img {
                    width: 30px;
                    height: 30px;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}

@keyframes glowInOut {
    0% {
        box-shadow: var(--brand100) 0px 0px 12px 12px;
    }
    50% {
        box-shadow: var(--brand100) 0px 0px 0px 0px;
    }
    100% {
        box-shadow: var(--brand100) 0px 0px 12px 12px;
    }
}

@keyframes rotateInfinte {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Button {
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid var(--brand100);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .s400;
    & svg {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
    }
    &--primary {
        @extend .Button;
        background-color: var(--brand100);
        color: var(--white);
        // &:hover {
        //     // @extend .Button--secondary;
        //     // opacity: 0.8;
        // }
    }
    &--secondary {
        @extend .Button;
        background-color: var(--grey500);
        color: var(--white);
        border: 1px solid #919191;
        // &:hover {
        //     // @extend .Button--primary;
        //     // opacity: 0.8;
        // }
    }
    &:hover {
        opacity: 0.7;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}
